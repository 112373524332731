<template>
    <div>
        <Banner_Component></Banner_Component>
        <RentalBoxOnHand_Component></RentalBoxOnHand_Component>
        <Footer_Compontent></Footer_Compontent>

        <router-view></router-view>
    </div>
</template>

<script>
import Banner_Component from '../components/Banner_Component.vue'
import RentalBoxOnHand_Component from '../components/RentalBoxOnHand/RentalBoxOnHand_Component.vue';
import Footer_Compontent from '../components/Footer_Compontent.vue'
import { homecityList, rentBoxList } from '../tools/ajax';
export default {
    name: "RentalBoxOnHand",
    components: { Banner_Component,  Footer_Compontent, RentalBoxOnHand_Component },
    data() {
        return {
            // departureStationValue: "",  //出发站值
            // departureStationList: [],  //出发站数据
            // destinationValue: "", //目的站值
            // selectBoxTypeValue: "", //选择箱型值
            // lineList: null, //推荐线路
            // lineList1: [],
            // selectBoxType: [{  //选择箱型
            //     value: '20GP',
            //     label: '20GP'
            // }, {
            //     value: '40GP',
            //     label: '40GP'
            // }, {
            //     value: '40HQ',
            //     label: '40HQ'
            // }, {
            //     value: '45HQ',
            //     label: '45HQ'
            // }, {
            //     value: '20OT',
            //     label: '20OT'
            // },
            // {
            //     value: '40OT',
            //     label: '40OT'
            // },
            // {
            //     value: '20NOR',
            //     label: '20NOR'
            // }, {
            //     value: '40NOR',
            //     label: '40NOR'
            // },
            // {
            //     value: '20FR',
            //     label: '20FR'
            // },
            // {
            //     value: '40FR',
            //     label: '40FR'
            // },
            // {
            //     value: '20ISO',
            //     label: '20ISO'
            // },],
            // formData: {
            //     pageNo: 1, //当前页
            // },
        }
    },
    // created() { //快捷订箱请求
    //     homecityList({ pageNo: '1' }).then((res) => {
    //         this.departureStationList = res.result.records;
    //     })
    //     // start_city_id=16&end_city_id=20&box_type_id=1
    //     this.pushQuery()
    // },
    // mounted() {

    // },
    // watch: {

    // },
    // methods: {
    //     async pushQuery() {
    //         this.departureStationValue = this.$route.query.start_city;
    //         this.destinationValue = this.$route.query.end_city;
    //         this.selectBoxTypeValue = this.$route.query.box_type;
    //         await rentBoxList({
    //             boxType: '现货',
    //             departure: this.departureStationValue,
    //             destination: this.destinationValue,
    //             cupboard: this.selectBoxTypeValue,

    //         }).then((res) => {
    //             if (res.code == 200) {
    //                 const ls = res.result.records;
    //                 this.lineList = ls
    //             };
    //         })

    //     },

    //     searchLine() {
    //         rentBoxList({
    //             boxType: '现货',
    //             departure: this.departureStationValue,
    //             destination: this.destinationValue,
    //             cupboard: this.selectBoxTypeValue,

    //         }).then((res) => {
    //             if (res.code == 200) {
    //                 this.lineList = res.result.records;
    //                 console.log(this.lineList);
    //             };
    //         })
    //     },

    //     //  #region
    //     //搜索框
    //     disableVisible(e) { //输入搜索
    //         // console.log("^^", e.target.value);
    //         homecityList({ name: e.target.value }).then((res) => {
    //             this.departureStationList = res.result.records;
    //         })
    //     },
    //     changeServer(e) { //输入搜索
    //         homecityList({ name: e.target.value }).then((res) => {

    //             this.departureStationList = res.result.records;
    //         })
    //     },
    //     // 懒加载
    //     loadmore() {
    //         this.formData.pageNo++;
    //         this.getList(this.formData);
    //     },
    //     // 下拉刷新
    //     getList(formData) {
    //         homecityList({ pageNo: formData.pageNo }).then((res) => {
    //             const _res = res.result.records;
    //             this.departureStationList = [...this.departureStationList, ..._res];
    //         })
    //     }
    //     //#endregio
    // },
}
</script>

<style lang="scss" scoped>
::v-deep.el-popper {
    overflow-y: scroll;
    width: 284px;
    max-height: 255px;
}

.el-dropdown-menu__item {
    // width: 142px; 
    height: 40px;
    font-size: 16px;
    font-weight: 400;
    color: #333333;
    line-height: 40px;
    // background: rgba(153,153,153,0.2)
}

.el-dropdown-menu__item:hover {
    background: rgba(153, 153, 153, 0.2);
    color: #333333;
    // box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.16);
}

// 滚动条样式
::-webkit-scrollbar {
    width: 4px;
    height: 48px;
    border-radius: 2px 2px 2px 2px;
}

.rental_Box {
    width: 1200px;
    margin: 56px auto 0;
}

.server {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 1200px;
    height: 94px;
    background: #FFFFFF;
    border-radius: 0px 16px 16px 16px;
    // border: 1px solid #999999;
    margin: 0 auto;
    // overflow: hidden;

    .selectS {
        width: 284px;
        height: 40px;
        background: #FFFFFF;
        border-radius: 4px 4px 4px 4px;

        .el-select {
            width: 100%;
            height: 100%;
        }
    }

    .server_Button {
        width: 120px;
        height: 40px;
        background: #FFC13B;
        box-shadow: 0px 3px 6px 1px rgba(255, 193, 59, 0.3);
        border-radius: 4px 4px 4px 4px;
        border: none;
        text-align: center;
        line-height: 40px;
        z-index: 10;
    }

    .el-dropdown {
        width: 284px;
        height: 40px;
        border-radius: 4px 4px 4px 4px;
        // border: 1px solid #999999;

        .el-button {
            position: relative;
            display: flex;
            // justify-content: space-between;
            align-items: center;
            width: 100%;
            height: 100%;
            font-size: 14px;
            background: #FFFFFF;
            border-radius: 4px 4px 4px 4px;
            font-weight: 400;
            color: #333333;
            line-height: 12px;
            border-color: #999999;

            .el-icon-arrow-down {
                position: absolute;
                right: 8px;
            }

        }
    }


}

.server::before {
    content: "";
    position: absolute;
    border: 1px solid #999999;
    border-radius: 0px 16px 16px 16px;
    width: 200%;
    height: 200%;
    transform: scale(.5);
    left: -50%;
    bottom: -51%;

}

.expressBook {
    display: flex;
    align-items: center;
    // justify-content: center;
    width: 224px;
    height: 60px;
    border-radius: 5px;
    background: url('../assets/homePage/searchTop.png') no-repeat;
    background-size: cover;
    overflow: hidden;

    img {
		margin-left:64px ;
        width: 24px;
        height: 18px;
        margin-right: 8px;
    }

    span {
        font-size: 14px;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 24px;
    }
}
</style>