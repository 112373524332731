<template>
    <div>
        <div class="rental_Box">
            <!--租箱  -->
            <div class="expressBook">
                <img src="../../assets/homePage/ship.png" alt="">
                <span>租箱</span>
            </div>
            <!-- 搜索 Inquiry -->
            <div class="server">
                <div class="selectS">
                    <el-select v-model="departureStationValue" placeholder="出发站" v-el-select-loadmore="loadmore"
                        filterable :filter-method="remoteMethod" @focus="changeFous">
                        <el-option v-for="(item, index) in departureStationList" :key="index" :label="item.city"
                            :value="item.city">
                        </el-option>
                    </el-select>
                </div>
                <div class="selectS">
                    <el-select v-model="destinationValue" placeholder="目的地" v-el-select-loadmore="loadmore" filterable
                        :filter-method="remoteMethod1" @focus="changeFous">
                        <el-option v-for="(item, index) in destinationListList" :key="index" :label="item.city"
                            :value="item.city">
                        </el-option>
                    </el-select>
                </div>
                <div class="selectS">
                    <el-select v-model="selectBoxTypeValue" placeholder="选择箱型">
                        <el-option v-for="item in selectBoxType" :key="item.value" :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </div>
                <!-- 出发站 -->
                <!--  -->
                <div class="server_Button" @click="searchLine"><i class="el-icon-search"></i> <span>搜索</span></div>
            </div>
        </div>

        <div class="recommend">
            <div class="recommend_Title">
                <p>推荐线路</p>
            </div>
        </div>

        <!-- 线路 -->
        <div class="line_Box">
            <!-- 热门城市 -->
            <div class="popularCities">
                <ul>
                    <li @click="handleAll" :class="{ 'handleactive': handleActive == -1 }"><span>全部</span></li>
                    <li v-for="(item, index) in recommendList" :key="index"
                        :class="{ 'handleactive': handleActive == index }" @click="handleAddress(item.address, index)">
                        <span>{{ item.address }}</span>
                    </li>
                </ul>
            </div>
            <div v-if="SpecialLineList !== ''">
                <div class="SpecialLine_Content">
                    <ul>
                        <li v-for="(item, index) in SpecialLineList" :key="index">
                            <div class="atMost">
                                <span>最多可订：</span>
                                <span>{{ item.maxBuy || item.expList[0].maxBuy }}柜</span>
                            </div>
                            <div class="boxType">
                                <span>箱子类型：</span>
                                <span>{{ item.cupboard }}</span>
                            </div>
                            <div class="address">
                                <p>{{ item.departure }}</p>
                                <p>
                                    <span>大约{{ item.period }}天</span>
                                    <img src="../../assets/homePage/departureAndArrival.png" alt="">
                                </p>
                                <p>{{ item.destination }}</p>
                            </div>
                            <div class="suitcase">
                                <p>
                                    <img src="../../assets/homePage/export.png" alt="">
                                    提箱
                                </p>
                                <p>
                                    <img src="../../assets/homePage/export.png" alt="" />
                                    还箱
                                </p>
                            </div>
                            <div class="score">
                                <div class="score_Left">
                                    <p>{{ item.expList[0].currency }} {{ item.price || item.expList[0].price }}</p>
                                </div>
                                <!-- 立即订箱 -->
                                <div class="score_Right">
                                    <div class="bookImmediately">
                                        <h6 @click="immediately(item.id, item.boxType)">立即订箱</h6>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                    <!-- 加载更多 -->
                    <div class="loadMore" @click="loadMoreS">
                        <span>加载更多</span>
                    </div>
                </div>

            </div>
            <div class="imgS" v-show="SpecialLineList == ''">
                <img src="../../assets/myHomePage/Empty state.png" alt="">
                <span>暂无数据</span>
            </div>

            <router-view></router-view>
        </div>
    </div>
</template>

<script>
import { homecityList, rentBoxList, recommend } from '../../tools/ajax';
import { mapState } from 'vuex';
import axios from 'axios';
import { baseUrl } from '../../tools/baseUrl';
export default {
    name: "RentalBoxOnHand_Component",
    data() {
        return {
            handleActive: null,
            departureStationValue: "",  //出发站值
            departureStationList: [],  //出发站数据
            destinationListList: [],  //出发站数据
            destinationValue: "", //目的站值
            selectBoxTypeValue: "", //选择箱型值
            lineList: null, //推荐线路
            lineList1: [],
            selectBoxType: [{  //选择箱型
                value: '20GP',
                label: '20GP'
            }, {
                value: '40GP',
                label: '40GP'
            }, {
                value: '40HQ',
                label: '40HQ'
            }, {
                value: '45HQ',
                label: '45HQ'
            }, {
                value: '20OT',
                label: '20OT'
            },
            {
                value: '40OT',
                label: '40OT'
            },
            {
                value: '20NOR',
                label: '20NOR'
            }, {
                value: '40NOR',
                label: '40NOR'
            },
            {
                value: '20FR',
                label: '20FR'
            },
            {
                value: '40FR',
                label: '40FR'
            },
            {
                value: '20ISO',
                label: '20ISO'
            },],
            recommendList: [{
                id: 0,
                address: "宁波"
            }, {
                id: 1,
                address: "大连"
            }, {
                id: 2,
                address: "合肥"
            }, {
                id: 3,
                address: "成都"
            }, {
                id: 4,
                address: "济南"
            }, {
                id: 5,
                address: "上海"
            }, {
                id: 6,
                address: "厦门"
            }, {
                id: 7,
                address: "漳州"
            }, {
                id: 8,
                address: "马拉"
            }, {
                id: 9,
                address: "西安"
            }, {
                id: 10,
                address: "武汉"
            }, {
                id: 11,
                address: "长沙"
            }, {
                id: 12,
                address: "重庆"
            }, {
                id: 13,
                address: "深圳"
            }, {
                id: 14,
                address: "东莞"
            }, {
                id: 15,
                address: "天津"
            }, {
                id: 16,
                address: "襄阳"
            }, {
                id: 17,
                address: "广州"
            }, {
                id: 18,
                address: "青岛"
            }, {
                id: 19,
                address: "郑州"
            }, {
                id: 20,
                address: "咸宁"
            }, {
                id: 21,
                address: "叶卡捷琳堡"
            }, {
                id: 22,
                address: "汉堡"
            }, {
                id: 23,
                address: "南通"
            }, {
                id: 24,
                address: "安特卫普"
            }, {
                id: 25,
                address: "鹿特丹"
            }, {
                id: 26,
                address: "纽伦堡"
            }, {
                id: 27,
                address: "罗兹"
            }, {
                id: 28,
                address: "杜伊斯堡"
            }, {
                id: 29,
                address: "布达佩斯"
            }, {
                id: 30,
                address: "无锡"
            }, {
                id: 31,
                address: "北京"
            }],
            SpecialLineList: [],
            formData: {
                pageNo: 1, //当前页
            },
            authInfo: "",
            pageNos: 1,
        }
    },
    watch: {
        ...mapState(['token'])
    },
    created() { //快捷订箱请求
        homecityList({ pageNo: '1' }).then((res) => {
            this.departureStationList = res.result.records;
            this.destinationListList = res.result.records;
        })
        // start_city_id=16&end_city_id=20&box_type_id=1

        rentBoxList({
            boxType: "现货",
            // pageNo: 1,
        }).then(res => {
            console.log(res);
            if (res.success == true) {
                this.SpecialLineList = res.result.records;
            } else {
                let messages = res.message;
                this.$message({
                    showClose: true,
                    message: messages,
                    type: 'error'
                });
            }

        });
        this.pushQuery();
        this.getauthenView();
    },
    methods: {
        getauthenView() {  //认证信息
            var token = sessionStorage.getItem("token")
            if (token) {
                axios.get(`${baseUrl}/authen/view`, {
                    headers: {
                        'authorization': token
                    }
                }).then(res => {
                    if (res.data.success == true) {
                        this.authInfo = res.data.result.status;
                        console.log(this.authInfo);
                    } else {
                        let message = res.data.message;
                        this.$message({
                            showClose: true,
                            message: message,
                            type: 'error'
                        });
                        return false
                    }
                })
            }


        },
        changeFous() {
            this.formData.pageNo = 1;
            homecityList().then(res => {
                console.log(res.result);
                this.departureStationList = res.result.records;
                this.destinationListList = res.result.records;
            });
        },
        remoteMethod(value) { //
            console.log("$$$", value);
            if (value) {
                axios.get(`${baseUrl}/basic/cityList`, {
                    params: { name: value }
                })
                    .then(res => {
                        console.log("---", res.data.result.records);
                        this.departureStationList = res.data.result.records;
                        // this.destinationListList = res.data.result.records;
                    })
                    .catch(err => {
                        console.error(err);
                    })
            } else {
                homecityList().then(res => {
                    console.log(res.result);
                    this.departureStationList = res.result.records;
                    // this.destinationListList = res.result.records;
                });
            }

        },
        remoteMethod1(value) {
            if (value) {
                axios.get(`${baseUrl}/basic/cityList`, {
                    params: { name: value }
                })
                    .then(res => {
                        console.log("---", res.data.result.records);
                        // this.departureStationList = res.data.result.records;
                        this.destinationListList = res.data.result.records;
                    })
                    .catch(err => {
                        console.error(err);
                    })
            } else {
                homecityList().then(res => {
                    console.log(res.result);
                    // this.departureStationList = res.result.records;
                    this.destinationListList = res.result.records
                });
            }
        },



        // #region  //搜索框
        // 懒加载
        loadmore() {
            this.formData.pageNo++;
            this.getList(this.formData);
        },
        // 下拉刷新
        getList(formData) {
            homecityList({ pageNo: formData.pageNo }).then((res) => {
                const _res = res.result.records;
                this.departureStationList = [...this.departureStationList, ..._res];
                this.destinationListList = [...this.destinationListList, ..._res];
            })
        },
        // #endregion
        async pushQuery() {
            this.departureStationValue = this.$route.query.start_city;
            this.destinationValue = this.$route.query.end_city;
            this.selectBoxTypeValue = this.$route.query.box_type;
            await rentBoxList({
                boxType: '现货',
                departure: this.departureStationValue,
                destination: this.destinationValue,
                cupboard: this.selectBoxTypeValue,

            }).then((res) => {
                if (res.code == 200) {
                    const ls = res.result.records;
                    this.lineList = ls
                };
            })

        },
        searchLine() {  //点击搜索
            rentBoxList({
                boxType: '现货',
                departure: this.departureStationValue,
                destination: this.destinationValue,
                cupboard: this.selectBoxTypeValue,

            }).then((res) => {
                console.log(res);
                if (res.success == true) {
                    this.SpecialLineList = res.result.records;
                    console.log(this.SpecialLineList);
                } else {
                    let messages = res.message;
                    this.$message({
                        showClose: true,
                        message: messages,
                        type: 'error'
                    });
                }
            })
        },
        immediately(id, boxType) { //点击立即订箱,跳转订单页
            this.getauthenView();
            let uesrInfo = sessionStorage.getItem("token");
            console.log(this.token == '');
            var authInfo = JSON.parse(localStorage.getItem("authInfo"));
            console.log(this.authInfo);
            if (uesrInfo == '' || uesrInfo == undefined || uesrInfo == null || !this.token == undefined || !this.token == '') {
                this.$message({
                    showClose: true,
                    message: '您还没有登录',
                    type: 'error'
                });
                return false
            } else if (this.authInfo !== 'Y') {
                this.$message({
                    showClose: true,
                    message: '您还未认证，请到个人中心认证！',
                    type: 'error'
                });
                return false
            } else if (this.token || uesrInfo) {
                this.$router.push(
                    {
                        path: "/Inquiry",
                        query: {
                            // item: this.SpecialLineList[index],
                            id: id,
                            boxType: boxType
                        }
                    }
                )
            }


        },
        handleAll() { //点击全部
            this.handleActive = -1;
            rentBoxList({
                boxType: "现货",
            }).then((res) => {
                this.SpecialLineList = res.result.records;
                console.log(res.result);
            })
        },
        handleAddress(address, index) {//点击地区
            this.handleActive = index;
            rentBoxList({
                boxType: "现货",
                departure: address,
                // destination:address,
            }).then((res => {
                console.log("$$", res.result.records);
                this.SpecialLineList = res.result.records;
                console.log(this.SpecialLineList == '');
            }))
        },
        loadMoreS() {
            this.pageNos++;
            rentBoxList({
                boxType: "现货",
                pageNo: this.pageNos,
            }).then((res) => {

                let newList = res.result.records;
                newList.forEach((v, i) => {
                    this.SpecialLineList.push(v);
                });
                // this.SpecialLineList.push(newList);
                // this.SpecialLineList.concat(newList)
                // console.log("$", newList);
            })

        },
    },
}
</script>

<style lang="scss" scoped>
.handleactive {
    background: #E9F4FF !important;
    border: 1px solid #1890FF !important;
}

.rental_Box {
    width: 1200px;
    margin: 56px auto 0;
}

.server {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 1200px;
    height: 94px;
    background: #FFFFFF;
    border-radius: 0px 16px 16px 16px;
    // border: 1px solid #999999;
    margin: 0 auto;
    // overflow: hidden;

    .selectS {
        width: 284px;
        height: 40px;
        background: #FFFFFF;
        border-radius: 4px 4px 4px 4px;

        .el-select {
            width: 100%;
            height: 100%;
        }
    }

    .server_Button {
        width: 120px;
        height: 40px;
        background: #FFC13B;
        box-shadow: 0px 3px 6px 1px rgba(255, 193, 59, 0.3);
        border-radius: 4px 4px 4px 4px;
        border: none;
        text-align: center;
        line-height: 40px;
        z-index: 10;
        cursor: pointer;
    }

    .el-dropdown {
        width: 284px;
        height: 40px;
        border-radius: 4px 4px 4px 4px;
        // border: 1px solid #999999;

        .el-button {
            position: relative;
            display: flex;
            // justify-content: space-between;
            align-items: center;
            width: 100%;
            height: 100%;
            font-size: 14px;
            background: #FFFFFF;
            border-radius: 4px 4px 4px 4px;
            font-weight: 400;
            color: #333333;
            line-height: 12px;
            border-color: #999999;

            .el-icon-arrow-down {
                position: absolute;
                right: 8px;
            }

        }
    }


}

.server::before {
    content: "";
    position: absolute;
    border: 1px solid #999999;
    border-radius: 0px 16px 16px 16px;
    width: 200%;
    height: 200%;
    transform: scale(.5);
    left: -50%;
    bottom: -51%;

}

.expressBook {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 224px;
    height: 60px;
    border-radius: 5px;
    background: url('../../assets/homePage/searchTop.png') no-repeat;
    background-size: cover;
    overflow: hidden;

    img {
        width: 24px;
        height: 18px;
        margin-right: 9px;
    }

    span {
        font-size: 14px;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 24px;
    }
}

.recommend {
    width: 1200px;
    margin: 54px auto 0;

    .recommend_Title {
        position: relative;
        font-weight: 400;
        color: #2B2B2B;
        line-height: 24px;
        font-size: 30px;
        padding-bottom: 16px;
        border-bottom: 1px solid #999999;

        p:after {
            position: absolute;
            left: 0;
            bottom: 0;
            content: '';
            width: 120px;
            height: 2px;
            background: #FFC13B;

        }
    }
}

.line_Box {
    width: 1200px;
    margin: 0 auto;

    .imgS {
        width: 150px;
        height: 300px;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        align-items: center;
        text-align: center;

        img {
            display: block;
            width: 150px;
            height: 104px;
            // margin: auto;    
        }

        span {
            text-align: center;
        }
    }

    .SpecialLine_Content {
        width: 100%;
        margin-top: 24px;

        ul {
            display: flex;
            // justify-content: flex-end;
            justify-content: space-between;
            flex-wrap: wrap;
            text-align: justify;

            li {
                box-sizing: border-box;
                width: 30%;
                height: 160px;
                background: linear-gradient(90deg, #FFFEFE 0%, #FFF8F5 100%);
                border-radius: 8px 8px 8px 8px;
                border: 1px solid #707070;
                // margin-right: 15px;
                margin-bottom: 12px;
                padding: 16px 16px 10px 16px;

                .atMost {
                    position: relative;
                    display: flex;
                    font-size: 12px;
                    font-weight: 400;
                    color: #2B2B2B;
                    line-height: 12px;
                    margin-bottom: 4px;
                    height: 12px;

                    span:nth-child(1) {
                        text-align: left;
                    }

                    span:nth-child(2) {
                        color: #D4252C;
                    }
                }

                .boxType {
                    font-size: 12px;
                    font-weight: 400;
                    color: #2B2B2B;
                    line-height: 12px;
                    height: 12px;
                }

                .address {
                    display: flex;
                    justify-content: space-between;
                    height: 20px;
                    margin-top: 24px;


                    p:nth-child(1) {
                        font-size: 20px;
                        font-weight: bold;
                        color: #1890FF;
                        line-height: 12px;
                    }

                    p:nth-child(2) {
                        font-size: 12px;
                        font-weight: 400;
                        color: #333333;
                        line-height: 12px;

                        span {
                            display: block;
                        }
                    }

                    p:nth-child(3) {
                        font-size: 20px;
                        font-weight: bold;
                        color: #1890FF;
                        line-height: 12px;
                    }
                }

                .suitcase {
                    display: flex;
                    justify-content: space-between;
                    font-size: 12px;
                    font-weight: 400;
                    color: #2B2B2B;
                    line-height: 12px;

                    img {
                        width: 15px;
                        height: 9px;
                    }

                }

                .score {
                    display: flex;
                    justify-content: space-between;
                    height: 30px;
                    margin-top: 18px;

                    .score_Left {
                        font-weight: bold;
                        color: #D4252C;
                        line-height: 24px;
                        font-size: 22px;
                    }

                    .score_Right {
                        display: none;
                        width: 72px;
                        height: 30px;
                        background: #FFC13B;
                        border-radius: 4px 4px 4px 4px;
                        line-height: 30px;
                        text-align: center;

                        h6 {
                            font-weight: normal;
                            cursor: pointer;
                        }

                    }
                }

            }

            li:hover {
                background: linear-gradient(90deg, #90C9FF 0%, #1890FF 100%);
                box-shadow: 0px 3px 6px 1px rgba(24, 144, 255, 0.3)
            }

            li:hover span {
                color: #FFFFFF !important;
            }

            li:hover .score_Right {
                display: block;
            }

            li:hover p {
                color: #FFFFFF !important;
            }

            li:nth-child(n+3) {
                margin-right: 0;
            }

            li:nth-child(6) {
                margin-right: 0;
            }
        }

        ul:after {
            content: "";
            width: 30%;
            // flex: auto;
            border: 1px solid transparent;
        }
    }

    .popularCities {
        width: 1200px;
        margin: 34px auto 0;

        ul {
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            font-size: 14px;
            font-family: '黑体';
            font-weight: 400;
            color: #999999;
            line-height: 34px;

            li {
                width: 100px;
                height: 34px;
                line-height: 34px;
                text-align: center;
                font-weight: 400;
                margin-left: 10px;
                margin-bottom: 8px;
                background: #FFFFFF;
                border-radius: 4px 4px 4px 4px;
                overflow: hidden;
                border: 1px solid #999999;
                cursor: pointer;

                span {

                    color: #999999;
                }
            }

            li:hover {
                background: #E9F4FF;
                border: 1px solid #1890FF;

                span {
                    color: #1890FF;
                }
            }

            li:nth-child(1) {
                margin-left: 0;
            }

            li:nth-child(12) {
                margin-left: 0;
            }

            li:nth-child(23) {
                margin-left: 0;
            }

            li:nth-child(34) {
                margin-left: 0;
            }
        }
    }

    .loadMore {
        cursor: pointer;
        width: 120px;
        height: 40px;
        background: #FFC13B;
        box-shadow: 0px 3px 6px 1px rgba(255, 193, 59, 0.3);
        border-radius: 4px 4px 4px 4px;
        text-align: center;
        line-height: 40px;
        margin-top: 12px;
        margin-bottom: 52px;
    }
}
</style>